body {
  font-family: Didot;
}

.nav-button {
  min-width: 280px;

  background-color: rgba(2, 2, 2, 0.7);

  padding: 15px 30px 15px 15px;

  border-radius: 5px;
}

.nav-title {
  font-size: 22px;
  font-weight: bold;

  line-height: 28px;
}

.sub-nav-container {
  overflow-y: scroll;
}

.sub-nav-container::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.scrollbar-container {
  background-color: rgba(0, 0, 0, 0.55);

  margin-left: 5px;

  width: 6px;
}

.scrollbar-thumb {
  background: rgba(0, 0, 0, 0.7);

  width: 100%;
  height: 15px;
  max-height: 100%;
}

.sub-nav-button {
  min-width: 246px;

  background-color: rgba(2, 2, 2, 0.7);

  padding: 10px 0 10px 15px;

  border-radius: 5px;
}

.checkmark-image {
  display: none;
}

.sub-nav-button--checked .checkmark-image {
  display: block;
}

.sub-nav-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
}

.chevron-image {
  transition: transform 0.3s ease-in-out;
}

.chevron-image--flipped {
  transform: rotate(180deg);
}

.sub-nav-scrollbar-container {
  max-height: 200px;

  transition: max-height 0.3s ease-in-out;
}

.sub-nav-scrollbar-container--collapsed {
  max-height: 0;
}
